 
  /* Round switch  */
  .switch{
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input{
    opacity: 0;
    width: 0;
    height: 0;
  }

  
  
  .slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #96dcee;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before{
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 3px;
    background-color: #fffaa8;
    border: 2px solid #f5eb71;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider{
    background-color: #808fc7;
  }
  
  input:focus + .slider{
    box-shadow: 0 0 1px #96dcee;
  }
  
  input:checked + .slider:before{
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: #fff;
    border: 2px solid #e8e8ea;
  }
  
  
  .slider.round{
    border-radius: 34px;
  }
  
  .slider.round:before{
    border-radius: 50%;
  }