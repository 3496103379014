@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

*{
  margin: 0;
  padding: 0;
}

body{
  font-family: Roboto, 'san-serif';
}

.home{
  display: grid;
  grid-template-columns: 0.6fr 2fr 0.6fr;
}

.info-column{
  background-color: white;
  padding: 20px;
  border-right: 1px solid #DEDFE0;
  border-left: 1px solid #DEDFE0;
}



.links{
  text-decoration: none;
  margin-right: 5px;
}

.icon-span{
  background-color: rgba(0, 0, 0, .6);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
}

.icon-span:hover{
  background-color: rgba(0, 0, 0, .8);
}

.connect-linkedIn {
  color: #fff;
}

.connect-linkedIn:hover{
  color: #fff;
}

.all-connect{
  display: flex;
  align-items: center;
}

.connect-icons{
  font-size: 1.5rem;
  margin-right: 5px;
  cursor: pointer;
  color: rgba(0, 0, 0, .6);
}

.connect-icons:hover{
  color: rgba(0, 0, 0, .8);
}

.twitter:hover{
  color: dodgerblue;
}

.youtube:hover{
  color: crimson;
}




.intro{
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.intro p{
  font-size: 1.2rem;
  
}


.intro img{
  width: 150px;
  margin: 10px;
  border: 5px solid #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: all ease 200ms;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}

.intro img:hover{
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.projects-section{
  margin: 30px 0;
}

.projects{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 0 20px;
  margin: 30px 0;
}

.blogs-section{
  margin: 30px 0;
}

.blogs{
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0;
}

#message{
  margin: 20px;
}

.blog-post{
  border-radius: .5rem;
  overflow: hidden;
  /* padding: 15px; */
  box-shadow: 0 3px 7px rgb(0 0 0 / 0.2);
}

.blog-post:hover{
  transform: scale(1.03);
}

.blog-post a h3{
  color: #000;
  font-size: 1rem;
}

.cta{
  padding: 5px 10px;
  background-color: #1f3142;
  color: #fff;
  border: none;
  border-radius: 10px;
}

a{
  text-decoration: none;
}


.blogs img{
  max-width: 100%;
  border-radius: .3rem .3rem 0 0;
}

.project{
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 3px 7px rgb(0 0 0 / 0.2);
}

.project:hover{
  transform: scale(1.03);
}

.project img{
  max-width: 100%;
}


/* nav css */
.navbar{
    display: grid; 
    grid-template-columns: 0.6fr 2fr 0.6fr;
    padding: 10px 0;
    background: #ffffff;
    border-bottom: 1px solid #DEDFE0;
    position: fixed;
    width: 100%;
    margin-bottom: 10px;
}

.dark-theme .navbar{
  border-bottom: 1px solid #9d9d9e;
}

.navbar-home{
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  z-index: 1001;
}


.nav-link{
    margin: 0;
    margin-left: 10px;
    padding: 0;
}

.nav-item{ 
    color: rgba(0, 0, 0, .7);
    margin-left: 15px;
    margin-right: 15px;
    font-weight: 500;
    font-size: 1.2rem;
    text-decoration: none;
}

#first-item{
  margin-left: 0;
}

.nav-cta{
  text-align: end;
  margin-right: 10px;
}






.dark-theme{
    --dark-background: #182735;
    --light-text: rgb(240, 240, 240);
    background-color: var(--dark-background);
    color: var(--light-text);
}

.dark-theme .navbar{
  background: #182735;
}

.dark-theme .intro{
  border-radius: 20px;
  background: #182735;
  }

  .dark-theme .intro img{
    box-shadow: 20px 20px 35px #191522;
    border: 5px solid #14222e;
  }

  .dark-theme .projects-section{
    background: #182735;
  }

  .dark-theme .blogs-section{
    background: #182735;
  }

  .dark-theme .foot{
    background: #182735;
  }

  .dark-theme .info-column{
    background-color: #182735;
    padding: 20px;
    border-right: 1px solid #9d9d9e;
    border-left: 1px solid #9d9d9e;
  }

  .dark-theme .nav-item{
    color: #fff;
  }






  
  .light-theme{
    --light-background: #F6F8FA;
    --dark-text: #000;
    background-color: var(--light-background);
    color: var(--dark-text);
  }
  
  .light-theme p{
    color: rgba(0, 0, 0, .6);
  }

  .light-theme .nav-item{
    color: rgba(0, 0, 0, .6);
  }


  .not-found{
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  



@media only screen and (max-width: 698px) {

  .home{
    display: flex;
    
  }

  .left-out{
    display: none;
  }

  .intro{
    display: flex;
    flex-direction: column;
    margin: 45px 0;
    height: 350px;
    }

    .projects{
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 30px 0;
      align-items: center;
    }

    .works{
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 30px 0;
      align-items: center;
    }

    .blogs{
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 30px 0;
      align-items: center;
    }

    .dark-theme .info-column{
      border-right: none;
      border-left: none;
    }
}



.works{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 0 20px;
  margin: 30px 0;
}

.tabs-list{
  text-decoration: none;
  list-style-type: none;
  font-size: 1.3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0;
  background-color: #dedfe0;
  margin-bottom: 0;
}

.dark-theme .tabs-list{
  background-color: #141f2b;
}

.tabs{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: rgb(92, 92, 92);
}



.tabs:hover{
  color: #000;
}


.active-tab{
  color: #000;
  background-color: #fff;
  border-top: 1px solid #dedfe0;
  border-left: 1px solid #dedfe0;
  border-right: 1px solid #dedfe0;
}

.dark-theme .active-tab{
  background-color: #182735;
  border-top: 1px solid #141f2b;
  border-left: 1px solid #141f2b;
  border-right: 1px solid #141f2b;
}

.cards{
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}



.dark-theme .cards{
  background-color: #182735;
  border: 1px solid #141f2b;
}


.content{
  display: none;
  border-bottom: 1px solid #dedfe0;
  border-left: 1px solid #dedfe0;
  border-right: 1px solid #dedfe0;
  padding: 20px 0;
  margin: 0;
  margin-bottom: 15px;
}

.dark-theme .content{
  border-bottom: 2px solid #141f2b;
  border-left: 2px solid #141f2b;
  border-right: 2px solid #141f2b;
}

.dark-theme .copyright{
  color: #fff;
}

.active-content{
  display: flex;
  justify-content: space-evenly;
}

.light-theme .skill{
  border: 1px solid #000;
}

.light-theme .skill-text{
  color: #000;
}

.dark-theme .skill-text{
  color: rgba(255, 255, 255, .7);
}

.dark-theme .skill{
  border: 1px solid rgba(255, 255, 255, .7);
}



